import jsonParse from "web/utils/data/parser/string/jsonParse";

import { ICategoryCount } from "web/types/CategoryCount";
import {
  IPageGeneratorModule,
  IParsedParameter,
  PageGeneratorModuleType,
} from "web/types/PageGenerator";

import { IPromoCounter } from "../pageGeneratorQuery";
import handleGraphicGTM from "./handleGraphicGTM";
import handleHtmlGTM from "./handleHtmlGTM";

interface IHandlePageGeneratorGTMArgs {
  modulesSorted: IPageGeneratorModule[];
  promoCounter: IPromoCounter[];
  availableCategories: ICategoryCount[];
  isMobile?: boolean;
}

const HANDLED_TYPES = [
  PageGeneratorModuleType.HTML,
  PageGeneratorModuleType.SLIDER,
  PageGeneratorModuleType.TRIPLE_GRAPHIC,
];

const handlePageGeneratorGTM = ({
  modulesSorted,
  promoCounter,
  availableCategories,
}: IHandlePageGeneratorGTMArgs) => {
  modulesSorted.forEach(({ type, parameters, id }) => {
    if (!HANDLED_TYPES.includes(type)) {
      return;
    }
    const promoItem = promoCounter?.find((item) => item.id === id);
    const params = jsonParse<IParsedParameter>(parameters);

    switch (type) {
      case PageGeneratorModuleType.SLIDER:
        handleGraphicGTM({
          promoItem,
          availableCategories,
          params,
          imageField: "graphic_file",
        });
        break;
      case PageGeneratorModuleType.TRIPLE_GRAPHIC:
        handleGraphicGTM({
          promoItem,
          availableCategories,
          params,
          imageField: "background_file",
        });
        break;
      case PageGeneratorModuleType.HTML:
        handleHtmlGTM({ promoItem, params });
        break;
      default:
        break;
    }
  });
};

export default handlePageGeneratorGTM;
