import { IGtmEcommerceImpressionPromotions } from "web/types/Gtm";
import { IParsedParameter } from "web/types/PageGenerator";

import { IPromoCounter } from "../pageGeneratorQuery";
import { createGtmImpressionProps, modifyImage } from "./gtmPromoEvent";

export interface IHandleHtmlGTMArgs {
  promoItem?: IPromoCounter;
  params: IParsedParameter;
}

const handleHtmlGTM = ({ promoItem, params }: IHandleHtmlGTMArgs) => {
  if (!promoItem || !params?.content) {
    return;
  }
  const parser = new DOMParser();
  const content = parser.parseFromString(params.content, "text/html");
  const images = content.getElementsByTagName("img");
  const gtmOptions = Object.values(images).map((image, index) => {
    const name = modifyImage(image.src);
    const options: IGtmEcommerceImpressionPromotions = {
      name,
      position: index + promoItem?.promoPosition,
      promotion_name: name,
      creative_slot: index + promoItem?.promoPosition,
    };

    return options;
  });

  if (gtmOptions) {
    createGtmImpressionProps(gtmOptions);
  }
};

export default handleHtmlGTM;
