/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { BaseQueryFn, FetchArgs } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";

import renewAfterGetToken from "web/api/renewAfterGetToken";

import isValidToken from "web/utils/system/essentials/isValidToken";
import isValidTokenAccess from "web/utils/system/essentials/isValidTokenAccess";

import baseQueryMSI from "./baseQueryMSI";
import baseQueryMagento from "./baseQueryMagento";
import isMSIQuery from "./utils/isMSIQuery";

export const baseQuery: BaseQueryFn<string | FetchArgs> = async (
  args,
  api,
  extraOptions,
  renewedToken?: { accessToken: string; token: string }
) => {
  let result;

  // @ts-ignore
  const shouldOmitToken = typeof args !== "string" && args.omitToken;

  if (isMSIQuery(args)) {
    if (!shouldOmitToken && !isValidTokenAccess() && !renewedToken) {
      return renewAfterGetToken(
        JSON.stringify(args),
        (tokenFromRenewal: { accessToken: string; token: string }) =>
          // @ts-ignore
          baseQuery(args, api, extraOptions, tokenFromRenewal)
      );
    }

    result = await baseQueryMSI(
      args,
      {
        ...api,
        extra: { token: renewedToken?.accessToken, omitToken: shouldOmitToken },
      },
      extraOptions
    );
  } else {
    if (!shouldOmitToken && !isValidToken() && !renewedToken) {
      return renewAfterGetToken(
        JSON.stringify(args),
        (tokenFromRenewal: { accessToken: string; token: string }) =>
          // @ts-ignore
          baseQuery(args, api, extraOptions, tokenFromRenewal)
      );
    }

    const updatedArgs =
      typeof args === "object" && args !== null ? { ...args } : args;
    if (
      renewedToken?.accessToken &&
      (updatedArgs as FetchArgs)?.body?.hasOwnProperty("tokenAccess")
    ) {
      (updatedArgs as FetchArgs).body.tokenAccess = renewedToken.accessToken;
    }
    result = await baseQueryMagento(
      updatedArgs,
      { ...api, extra: { token: renewedToken?.token } },
      extraOptions
    );
  }

  if (result?.error && result.error.status === 401) {
    return renewAfterGetToken(
      JSON.stringify(args),
      baseQuery.bind(this, args, api, extraOptions)
    );
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: [
    "Wishlist",
    "Reminders",
    "Company",
    "Messages",
    "Messages_unread",
    "Popups",
    "BuyNow",
    "Customer",
    "Employee",
    "Employee_agreements",
    "Employee_configuration",
    "Employee_pointsHistory",
    "PaymentsMethods",
    "PrepaidCards",
    "PrepaidCards_details",
    "PrepaidCardsOrders",
    "PrepaidCardsOrders_details",
    "PointsBank",
    "CurrentUserSubscriptions",
    "AvailableUserBenefits",
    "EmployeeSubscriptionDraft",
    "EmployeeSubscriptionDraftForms",
    "EmployeeSubscriptionDraftAgreements",
    "FilterBenefitGroups",
    "DuplicateCardConfiguration",
    "Employee_RG_Additional_Data",
    "Employee_Intercom",
    "DuplicateCardConfiguration",
    "CodeSettings",
    "ProductCode",
    "BarCode",
    "MFAConfiguration",
    "CustomerTransactionHistory",
  ],
  endpoints: () => ({}),
});

export default apiSlice;
