/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { ICategoryCount } from "web/types/CategoryCount";
import { IParsedParameter } from "web/types/PageGenerator";
import { Nullable } from "web/types/Utils";

import { IPromoCounter } from "../pageGeneratorQuery";
import Triple from "./triple";

interface IPageGeneratorTripleContainerProps {
  isMobile: boolean;
  params: IParsedParameter;
  availableCategories: ICategoryCount[];
  promoItem: Nullable<IPromoCounter>;
}

const PageGeneratorTripleContainer: FC<IPageGeneratorTripleContainerProps> = ({
  params,
  isMobile,
  availableCategories = [],
  promoItem,
}) => {
  const images = useMemo(() => {
    return Object.keys(params).map((key) => {
      // @ts-ignore
      return typeof params[key] !== "string" && params[key];
    });
  }, [JSON.stringify(params)]);

  const imagesToRender = useMemo(() => {
    return images.filter((image) => {
      if (image.image_category_ids) {
        if (image.image_category_ids.includes(",")) {
          const slideCategoryIdsArray = image.image_category_ids.split(",");
          const areAllCategoriesAvailable = slideCategoryIdsArray.every(
            (slideIdCat: string) => {
              return (
                availableCategories &&
                availableCategories.find((availableCat) => {
                  return +availableCat.id === +slideIdCat;
                })
              );
            }
          );
          return areAllCategoriesAvailable ? image : null;
        }
        return isArrayHasItems(availableCategories)
          ? availableCategories.find((category) => {
              return (
                image.image_category_ids === category.id &&
                +category.product_count > 0
              );
            })
          : null;
      }
      return image;
    });
  }, [images]);

  return (
    <Triple
      images={imagesToRender}
      isMobile={isMobile}
      promoPosition={promoItem?.promoPosition}
    />
  );
};

interface IPageGeneratorTripleContainerErrorProps {
  isMobile: boolean;
  params: IParsedParameter;
  availableCategories: ICategoryCount[];
  promoItem?: IPromoCounter;
}

const PageGeneratorTripleContainerError: FC<
  IPageGeneratorTripleContainerErrorProps
> = ({
  isMobile = false,
  params,
  availableCategories = [],
  promoItem = null,
}) => {
  return (
    <ErrorBoundary>
      <PageGeneratorTripleContainer
        params={params}
        isMobile={isMobile}
        availableCategories={availableCategories}
        promoItem={promoItem}
      />
    </ErrorBoundary>
  );
};

export default PageGeneratorTripleContainerError;
