import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { ICategoryCount } from "web/types/CategoryCount";
import { IParsedParameter } from "web/types/PageGenerator";

import { IPromoCounter } from "../pageGeneratorQuery";
import getImagesToRender from "./getImagesToRender";
import { createGtmImpressionProps, modifyImage } from "./gtmPromoEvent";

interface IHandleGraphicGTMArgs {
  promoItem?: IPromoCounter;
  availableCategories: ICategoryCount[];
  params: IParsedParameter;
  imageField: keyof IParsedParameter;
}

const handleGraphicGTM = ({
  promoItem,
  availableCategories,
  params,
  imageField,
}: IHandleGraphicGTMArgs) => {
  const imagesToRender = getImagesToRender({ params, availableCategories });
  if (isArrayHasItems(imagesToRender)) {
    const gtmOptions = imagesToRender.map((image, index) => {
      const name = modifyImage(image[imageField] as string);
      return {
        name,
        position: index + (promoItem as IPromoCounter)?.promoPosition,
        promotion_name: name,
        creative_slot: index + (promoItem as IPromoCounter)?.promoPosition,
      };
    });

    if (gtmOptions) {
      createGtmImpressionProps(gtmOptions);
    }
  }
};

export default handleGraphicGTM;
