import { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

import SubscriptionBanner from "web/Layout/SubscriptionBanner";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import questionnaireScript from "web/utils/questionnaire";
import BrowserTemporality from "web/utils/system/storage/storage/browserTemporality";

import storageNames from "web/constants/storageNames";

import { ICategoryCount } from "web/types/CategoryCount";
import {
  IPageGeneratorModule,
  IParsedParameter,
} from "web/types/PageGenerator";

import Application from "./Application";
import Html from "./Html";
import HtmlExtended from "./HtmlExtended";
import InfiniteScrollWrapper from "./InfiniteScroll";
import throttle from "./InfiniteScroll/throttle";
import Newsletter from "./Newsletter";
import ProductCarousel from "./ProductCarousel";
import Reviews from "./Reviews";
import Single from "./Single";
import Slider from "./Slider";
import Text from "./Text";
import TourismBanner from "./TousrismBanner";
import Triple from "./Triple";
import TwoProducts from "./TwoProducts";
import { IListingCounter, IPromoCounter } from "./pageGeneratorQuery";
import handlePageGeneratorGTM from "./utils/handlePageGeneratorGTM";

const storage = new BrowserTemporality();

const getInitialState = (
  isHomePage: boolean,
  modulesSorted: IPageGeneratorModule[]
) => {
  if (!isHomePage) return { hasMore: false, length: modulesSorted.length };

  if (storage.getItem(storageNames.modulesHomepage)) {
    return {
      hasMore: true,
      length: storage.getItem(storageNames.modulesHomepage).modules.count,
    };
  }

  return {
    hasMore: true,
    length: modulesSorted.length >= 4 ? 4 : modulesSorted.length,
  };
};

interface IPageGeneratorProps {
  modulesSorted: IPageGeneratorModule[];
  name?: string;
  listingCounter: IListingCounter[];
  promoCounter: IPromoCounter[];
  availableCategories: ICategoryCount[];
  isMobile?: boolean;
}

const PageGenerator: FC<IPageGeneratorProps> = ({
  modulesSorted,
  isMobile = false,
  name = "",
  listingCounter,
  promoCounter,
  availableCategories,
}) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const containerRef = useRef<HTMLDivElement>(null);
  const modulesLoadedValue = useRef(0);
  const [modules, setModules] = useState(() =>
    getInitialState(isHomePage, modulesSorted)
  );

  const handleResize = throttle(50, () => {
    // if container height is less than window height
    // it means a user has either ultra big screen or low zoom
    // in this case load all modules and disable lazy load
    if (window.innerHeight >= (containerRef?.current?.offsetHeight as number)) {
      setModules({
        hasMore: false,
        length: modulesSorted.length,
      });
    }
  });

  useEffect(() => {
    window.addEventListener(
      "resize",
      handleResize as EventListenerOrEventListenerObject
    );
    handleResize();
    return () => {
      if (isHomePage && !storage.getItem(storageNames.modulesHomepage)) {
        storage.setItem(storageNames.modulesHomepage, "modules", {
          count: modulesLoadedValue.current,
        });
      }
      window.removeEventListener(
        "resize",
        handleResize as EventListenerOrEventListenerObject
      );
    };
  }, []);

  useEffect(() => {
    handlePageGeneratorGTM({
      modulesSorted,
      promoCounter,
      availableCategories,
    });
  }, []);

  const touchBottomCallback = () => {
    if (modules.length >= modulesSorted.length) {
      setModules({
        ...modules,
        hasMore: false,
      });
      return;
    }

    setModules((prev) => ({
      hasMore: true,
      length: prev.length + 1,
    }));

    if (isHomePage) modulesLoadedValue.current = modules.length + 1;
  };

  return (
    <>
      <Helmet>
        <title>{name}</title>
        {window.ReactNativeWebView && (
          <script type="text/javascript">{questionnaireScript}</script>
        )}
      </Helmet>
      <div ref={containerRef}>
        <InfiniteScrollWrapper
          dataLength={modules.length}
          next={touchBottomCallback}
          scrollThreshold={0.5}
          hasMore={modules.hasMore}
          isHomePage={isHomePage}
        >
          {modulesSorted
            .slice(0, modules.length)
            .map(({ type, parameters, id }) => {
              const productListing = listingCounter.find(
                (item) => item.id === id
              );
              const promoItem = promoCounter?.find((item) => item.id === id);
              const productListingPosition = productListing?.listingPosition;
              const parametersParsed: IParsedParameter = jsonParse(parameters);
              const moduleCategoryIds = parametersParsed?.module_category_ids
                ? parametersParsed?.module_category_ids.split(",")
                : null;

              let moduleCategoryBool = !moduleCategoryIds;

              if (moduleCategoryIds) {
                moduleCategoryBool = moduleCategoryIds.every((module) => {
                  return availableCategories.find((category) => {
                    return (
                      +category.id === +module && +category.product_count > 0
                    );
                  });
                });
              }

              if (!moduleCategoryBool) {
                return null;
              }

              switch (type) {
                case "html":
                  return (
                    <Html
                      key={id}
                      params={parameters}
                      isMobile={isMobile}
                      promoItem={promoItem}
                    />
                  );
                case "html_extended":
                  return (
                    <HtmlExtended
                      key={id}
                      params={parameters}
                      id={id}
                      isMobile={isMobile}
                    />
                  );
                case "text":
                  return (
                    <Text isMobile={isMobile} key={id} params={parameters} />
                  );
                case "single_graphic":
                  return (
                    <Single
                      key={id}
                      params={parameters}
                      isMobile={isMobile}
                      id={id}
                    />
                  );
                case "triple_graphic":
                  return (
                    <Triple
                      key={id}
                      params={jsonParse(parameters)}
                      availableCategories={availableCategories}
                      isMobile={isMobile}
                      promoItem={promoItem}
                    />
                  );
                case "app_home_page": {
                  return <Application isMobile={isMobile} key={id} />;
                }
                case "newsletter":
                  return <Newsletter isMobile={isMobile} key={id} />;
                case "two_products":
                  return (
                    <TwoProducts
                      isMobile={isMobile}
                      key={id}
                      params={parameters}
                      listingPosition={productListingPosition}
                      listingAmount={listingCounter?.length}
                    />
                  );
                case "last_reviews":
                  return (
                    <Reviews isMobile={isMobile} key={id} params={parameters} />
                  );
                case "slider":
                  return (
                    <Slider
                      isMobile={isMobile}
                      key={id}
                      availableCategories={availableCategories}
                      params={jsonParse(parameters)}
                      promoItem={promoItem}
                    />
                  );
                case "product_carousel":
                  return (
                    <ProductCarousel
                      isMobile={isMobile}
                      key={id}
                      params={parameters}
                      listingPosition={productListingPosition}
                      listingAmount={listingCounter?.length}
                    />
                  );
                case "tourism_banner":
                  return <TourismBanner key={id} params={parameters} />;
                case "subscriptions":
                  return !isMobile && <SubscriptionBanner key={id} />;
                default:
                  return null;
              }
            })}
        </InfiniteScrollWrapper>
      </div>
    </>
  );
};

export default PageGenerator;
