import { ReactElement } from "react";

import { Nullable, WithRequired } from "web/types/Utils";

export const enum SubscriptionCheckoutSteps {
  CONFIGURATION = "configuration",
  INFORMATIONS = "informations",
  AGREEMENTS = "agreements",
  SUMMARY = "summary",
}

export enum SubscriptionGroups {
  STANDARD = "1",
  UMS = "2",
}

export enum SubscriptionResourceType {
  ACTIVE_FORM = "ActiveForm",
  ATTACHMENT = "Attachment",
  ATTACHMENT_GROUP = "BenefitGroupAttachment",
  ATTACHMENT_SUBGROUP = "BenefitSubgroupAttachment",
  ATTACHMENT_FILE = "AttachmentFile",
  BENEFIT = "Benefit",
  BENEFIT_GROUP = "BenefitGroup",
  BENEFIT_EMPLOYEE_GROUP = "BenefitEmployeeGroup",
  EMPLOYEE_SUBSCRIPTION = "EmployeeSubscription",
  EMPLOYEE_SUBSCRIPTION_DRAFT = "EmployeeSubscriptionDraft",
  EMPLOYEE_SUBSCRIPTION_ITEM = "EmployeeSubscriptionItem",
  FORM = "Form",
  PDF_FORM = "PdfForm",
  PRICE_TYPE_VALUE_OBJECT = "PriceTypeValueObject",
  PRICE_VARIANT_VALUE_OBJECT = "PriceVariantValueObject",
  RECEIVER_FORM = "ReceiverForm",
  RECEIVER_FORM_FIELD = "ReceiverFormField",
  SUBSCRIPTION_DRAFT_ITEM = "SubscriptionDraftItem",
  SUBSCRIPTION_ITEM_RECEIVER = "SubscriptionItemReceiver",
  TOTALS = "Totals",
  TOTALS_ITEM = "ItemTotal",
}

export interface ISubscriptionUserConfiguration {
  configuratorWindowOpen: boolean;
  configuratorWindowOpenUms: boolean;
  employeeId: string;
  id: string;
  individual: boolean;
  individualUms: boolean;
  lastConfigurationDone?: string;
  open: boolean;
  openUms: boolean;
  selectionWindowOpenToDate: string;
  selectionWindowOpenToDateUms: string;
  selectionWindowOpenToDay: number;
  selectionWindowOpenToDayUms: number;
  selectionWindowType: string;
  selectionWindowTypeUms: string;
}

export interface ISubscriptionConfigWithEmployeeSettings
  extends ISubscriptionUserConfiguration {
  showSubscriptionsInListing?: Nullable<string>;
  showSubscriptionsInListingUMS?: Nullable<string>;
}

export interface ISubscriptionUserCurrent {
  "@context": string;
  "@id": string;
  "@type": SubscriptionResourceType.EMPLOYEE_SUBSCRIPTION;
  agreementsPdfUrl?: string;
  benefitGroupType: number;
  employerTaxAmount?: string;
  id: string;
  items: ISubscriptionItemCurrentUserSubscription[];
  benefitsWithFormsToUpdate?: ISubscriptionItemCurrentUserSubscription[];
  ownerId: string;
  pointsBanks: IPointsBank[];
  pointsToBank: string;
  selectionWindowOpenToDate: string;
  selectionWindowOpenToDay: number;
  selectionWindowType: SubscriptionWindowType;
  totals: ISubscriptionTotals;
  subscriptionThresholdDay: string;
}

export interface ISubscriptionFilterBenefitGroups {
  benefitGroups: {
    [key: string]: {
      price: Nullable<number>;
    };
  };
}

export interface ISubscriptionUserInfoData {
  group: number;
  image: string;
  isGroupAvailable: boolean;
  isWindowOpen: boolean;
  selectionWindowOpenToDate: string;
  selectionWindowOpenToDay: number;
  selectionWindowType: string;
  showOnListing: boolean;
  lastConfigurationDone?: string;
  subscriptionThresholdDay?: Nullable<string>;
}

interface ISubscriptionDraftBase {
  "@context": string;
  "@id": string;
  "@type": SubscriptionResourceType.EMPLOYEE_SUBSCRIPTION_DRAFT;
}

export interface ISubscriptionDraftBenefits extends ISubscriptionDraftBase {
  benefits: ISubscriptionDraftItem<string>[];
  pointsToBank: string;
  id: string;
  totals: ISubscriptionTotals;
}

export interface IDraftBenefitsItem {
  "@id": string;
  "@type": string;
  id: string;
  quantity: number;
  value: string;
  benefit: string;
  pricing: {
    "@id": string;
    "@type": string;
    currentPriceType: {
      "@type": string;
      employerPriceAlternativeText: string | null;
    };
  };
  benefitEmployeeGroupPrice: {
    "@id": string;
    "@type": string;
    pointsBankId: string | null;
    pointsBankName: string | null;
    currentPriceVariant: {
      "@type": string;
      employeePrice: Nullable<string>;
      employerPrice: Nullable<string>;
      pointsBankPrice: Nullable<string>;
      employeePayUPrice: Nullable<string>;
    };
  };
  collectedDataScope: string | null;
}

export interface IItemDraft extends ISubscriptionDraftBase {
  benefitGroupType: number;
  benefits: IDraftBenefitsItem[];
  id: string;
  pointsBanks: [];
  pointsToBank: string;
  totals: ISubscriptionTotals;
}

export interface ICheckoutSubscriptionBenefitsItem {
  benefit: ISubscriptionAvailableBenefit;
  classes?: { [key: string]: string };
  itemsLocked: boolean;
  groupId: string;
  setModal: (content: ReactElement) => void;
  visibleName: string;
  visibleDescription: Nullable<string>;
  groupType: number;
  groupName: string;
  draft?: ISubscriptionDraftBenefits;
  benefitGroupAttachments?: ISubscriptionAttachment[];
  isGroupBlockedByAnother: boolean;
  allBenefits: any;
  getBenefitData: any;
  getAutoBenefitGroupId: (autoBenefitId: Nullable<string>) => string;
}

export interface IReadyBenefits {
  id: string;
  replacingBenefits: string[];
}
export interface ISubscriptionConfiguratorValuesFormikEle {
  benefitEmployeeGroupPrice?: string;
  benefitSubgroup: Nullable<{
    id: string;
    name: string;
    minChoices: Nullable<number>;
    maxChoices: Nullable<number>;
    minPeople: Nullable<number>;
    maxPeople: Nullable<number>;
    validated?: boolean;
  }>;
  commissionPercentage: number;
  employee: string | number;
  employeePayU: string | number;
  employer: string | number;
  hidden: boolean;
  id: string;
  name: string;
  points: string | number;
  pointsBank: string;
  pointsBankId: string;
  priceMethod?: string;
  pricing?: string;
  purchaseMethod: string;
  quantity: number;
  chosenButUnSelected?: boolean;
  resignedPeopleCurrentSession: number;
  receivers: any;
  selected: boolean;
  status?: SubscriptionStatus;
  showVariantPrice: boolean;
  variant: string;
  variantId: string;
}
export interface ISubscriptionConfiguratorValuesFormik {
  [key: string]: ISubscriptionConfiguratorValuesFormikGroup;
}

export interface ISubscriptionConfiguratorValuesFormikGroup {
  benefits: {
    [key: string]: ISubscriptionConfiguratorValuesFormikEle;
  };
  errorMessage?: string;
  hasError: boolean;
  maxChoices: Nullable<number>;
  minChoices: Nullable<number>;
  maxPeople: Nullable<number>;
  minPeople: Nullable<number>;
}
export interface ISubscriptionDraftForms extends ISubscriptionDraftBase {
  agreementsPdfUrl?: string;
  benefits: ISubscriptionDraftItem<ISubscriptionBenefit>[];
  benefitGroupType?: number;
  employerTaxAmount?: string;
  pointsBanks?: ISubscriptionPointsBank[];
  pointsToBank: string;
  totals: ISubscriptionTotals;
}

export interface ISubscriptionDraftBenefitsAgreements
  extends ISubscriptionDraftBase {
  agreements: ISubscriptionAgreement[];
  agreementsPdfUrl?: string;
  benefitGroupAgreements: ISubscriptionBenefitGroupAgreement[];
  benefitGroupType?: string;
  declarationPdf?: Nullable<string>;
  declarationType: SubscriptionDeclarationType;
  pointsBanks?: ISubscriptionPointsBank[];
  totals: ISubscriptionTotals;
}

export interface ISubscriptionBenefitGroupAgreement {
  agreements: ISubscriptionAgreement[];
  benefitAgreements: ISubscriptionBenefitAgreement[];
  id: string;
  name: string;
  photo: string;
}

export interface ISubscriptionItem<T = ISubscriptionBenefit> {
  "@id": string;
  "@type": SubscriptionResourceType.EMPLOYEE_SUBSCRIPTION_ITEM;
  acceptedAt?: string;
  activeForms: ISubscriptionActiveForm[];
  benefit: T;
  benefitEmployeeGroup: string;
  benefitEmployeeGroupPrice: string;
  benefitGroupType: number;
  benefitReceiver: SubscriptionFormReceiver[];
  description: Nullable<string>;
  endsAt?: string;
  expectedEndsAt?: string;
  employeePayrollNumber?: string;
  employeePayUPrice: string;
  employeePrice: string;
  employerPayrollNumber?: string;
  employerPrice: string;
  employerPriceAlternativeText?: string;
  employerPriceType: SubscriptionEmployerPriceType;
  externalActiveForms: boolean;
  forms: ISubscriptionForm[];
  graceDate?: string;
  groupName: string;
  method: SubscriptionMethod;
  name: string;
  ownerId: string;
  pdfBarcodes: ISubscriptionPdfBarcode[];
  pdfForms: ISubscriptionPdfForm[];
  pointsBankPrice: string;
  pointsBankId: string;
  pointsBankName: string;
  purchaseMethod: SubscriptionPurchaseMethod;
  quantity: number;
  receivers: ISubscriptionReceiver[];
  resignAfterActivation?: {
    "@id": string;
    "@type": string;
  };
  startsAt?: string;
  status: SubscriptionStatus;
  supplierId: string;
  terminatedAt?: string;
  variant?: string;
}

export type ISubscriptionItemCurrentUserSubscription =
  ISubscriptionItem<ISubscriptionBenefitCurrentUserSubscription>;

type DraftBenefit = string | ISubscriptionBenefit;

export interface ISubscriptionDraftItem<T extends DraftBenefit> {
  "@id": string;
  "@type": SubscriptionResourceType.SUBSCRIPTION_DRAFT_ITEM;
  acceptedAt?: string;
  activeForms: ISubscriptionActiveForm[];
  benefit: T;
  benefitEmployeeGroup: string;
  benefitEmployeeGroupPrice: IbenefitEmployeeGroupPrice;
  benefitEmployeeGroupPriceVariant?: string;
  benefitGroupType: number;
  benefitReceiver: SubscriptionFormReceiver[];
  collectedDataScope: SubscriptionCollectedDataScope;
  description: Nullable<string>;
  employeePayrollNumber?: string;
  employeePayUPrice: string;
  employeePrice: string;
  employerPayrollNumber?: string;
  employerPrice: string;
  employerPriceAlternativeText?: string;
  employerPriceType: SubscriptionEmployerPriceType;
  endsAt?: string;
  expectedEndsAt: Nullable<string>;
  externalActiveForms: boolean;
  forms: ISubscriptionForm[];
  groupName: string;
  id: string;
  method: SubscriptionMethod;
  name: string;
  ownerId: string;
  pdfBarcodes: ISubscriptionPdfBarcode[];
  pdfForms: ISubscriptionPdfForm[];
  pointsBankId: string;
  pointsBankName: string;
  pointsBankPrice: string;
  pricing: {
    "@id": string;
    "@type": SubscriptionResourceType.BENEFIT_EMPLOYEE_GROUP;
    currentPriceType: {
      "@id": string;
      "@type": SubscriptionResourceType.PRICE_TYPE_VALUE_OBJECT;
      employerPriceAlternativeText: Nullable<string>;
      employerPriceType: SubscriptionEmployerPriceType;
    };
    currentPriceVariant: {
      "@id": string;
      "@type": SubscriptionResourceType.PRICE_VARIANT_VALUE_OBJECT;
      employeePrice: string;
      employerPrice: string;
    };
  };
  purchaseMethod: SubscriptionPurchaseMethod;
  quantity: number;
  receivers: ISubscriptionReceiver[];
  resignAfterActivation?: {
    "@id": string;
    "@type": string;
  };
  startsAt?: string;
  status: SubscriptionStatus;
  supplierId: string;
  terminatedAt?: string;
  value: string;
  variant?: string;
}

export interface ISubscriptionSubmitResponse {
  agreements: ISubscriptionAgreement[];
  benefitGroupAgreements: ISubscriptionBenefitGroupAgreement[];
  declarationPdf: Nullable<string>;
  declarationType: SubscriptionDeclarationType;
  subscription: string;
}

// TODO: check if manualActivation & relationBenefit can be merged interfaces

export enum RelationBenefitAutomaticTypeConfiguration {
  HIDE_IN_MY_SUBSCRIPTIONS_VIEW = "hide_in_my_subscriptions_view",
  HIDE_WHEN_CHOOSING_BENEFITS = "hide_when_choosing_benefits",
  CAN_BE_REPLACED = "can_be_replaced",
}

// TODO: add data structure for groups & subgroups
export interface ISubscriptionBenefit {
  "@id": string;
  "@type": SubscriptionResourceType.BENEFIT;
  attachments: ISubscriptionAttachment[];
  benefitGroup: IBenefitGroup;
  benefitSubgroup?: {
    benefitSubgroupAttachments: ISubscriptionAttachment[];
  };
  collectedDataScope?: SubscriptionCollectedDataScope;
  enableOrderDuplicateCard: boolean;
  id?: string;
  manualActivationRequired: boolean;
  name?: string;
  relationBenefitAutomaticTypeConfiguration?: Nullable<
    RelationBenefitAutomaticTypeConfiguration[]
  >;
  relationBenefitFreeTypeConfiguration?: Nullable<SubscriptionRelationFreeType>;
  relationBenefitFreeTypeConfigurationDependingOn?: string[];
  relationBenefitFreeTypeConfigurationReplacing?: string;
  relationBenefitTypeConfiguration: SubscriptionRelationType;
  relationExtensionPeriod?: Nullable<number>;
  relationPeriodResignationBlockade?: Nullable<number>;
  relationRestrictionPeriod?: Nullable<number>;
  wayChooseOthersInActiveForm: SubscriptionFormReceiver;
}

export interface IBenefitGroup {
  "@id": string;
  "@type": SubscriptionResourceType.BENEFIT_GROUP;
  id: string;
  groupPhoto?: IGroupPhoto;
  groupType?: number;
  benefitGroupAttachments: ISubscriptionAttachment[];
}

export interface IGroupPhoto {
  contentUrl: string;
  filePath: string;
  id: string;
  originalName: string;
}

export type ISubscriptionBenefitCurrentUserSubscription = WithRequired<
  ISubscriptionBenefit,
  "collectedDataScope"
>;

export interface ISubscriptionAvailableBenefit {
  attachments: ISubscriptionAttachment[];
  benefitCategory: string;
  benefitEmployeeGroupDetailed: Nullable<ISubscriptionEmployeeGroup>;
  benefitGroup: string;
  benefitReceiver: SubscriptionFormReceiver[];
  benefitSubgroup?: {
    benefitDescription: string;
    benefitSubgroupAttachments: ISubscriptionAttachment[];
    billingType: string;
    id: string;
    name: string;
    minChoices: Nullable<number>;
    maxChoices: Nullable<number>;
    minPeople: Nullable<number>;
    maxPeople: Nullable<number>;
    periodSingleBillingType: number;
    position: number;
  };
  chosen: boolean;
  code: Nullable<string>;
  collectedDataScope: SubscriptionCollectedDataScope;
  currentBenefitEmployeeGroupPriceId: Nullable<string>;
  currentBenefitEmployeeGroupPriceVariantId: Nullable<string>;
  description: Nullable<string>;
  descriptionIsDefault: boolean;
  enableReactivationSubscription: boolean;
  endsAt: Nullable<string>;
  expectedEndsAt?: Nullable<string>;
  groupId: string;
  id: string;
  manualActivationRequired: boolean;
  manualActivationIsDefault: boolean;
  manualActivationType: Nullable<SubscriptionManualActivationType>;
  manualActivationTypeIsDefault: boolean;
  maxPeople: number;
  modeReactivationSubscription: ModeReactivationSubscription;
  name: string;
  pendingDay: Nullable<number>;
  position: number;
  purchaseMethod: SubscriptionPurchaseMethod;
  relationBenefitAutomaticTypeConfiguration: Nullable<string[]>;
  relationBenefitFreeTypeConfiguration: Nullable<SubscriptionRelationFreeType>;
  relationBenefitFreeTypeConfigurationDependingOn: Nullable<string[]>;
  relationBenefitFreeTypeConfigurationReplacing: Nullable<string>;
  relationBenefitTypeConfiguration: SubscriptionRelationType;
  relationExtendedBenefits: string[];
  relationExtensionPeriod: Nullable<number>;
  relationParentalLeave: Nullable<string>;
  relationPeriodResignationBlockade: number;
  relationResignationAvailable: boolean;
  relationRestrictedBenefits: string[];
  relationRestrictionPeriod: Nullable<number>;
  relationExtendedByBenefits: string[];
  relationRestrictedByBenefits: string[];
  quantity: Nullable<number>;
  sportCard: boolean;
  startsAt: Nullable<string>;
  status?: SubscriptionStatus;
  supplierId: string;
  thresholdDay: Nullable<number>;
  wasActivated: boolean;
  wayChooseOthersInActiveForm: SubscriptionFormReceiver;
}
export interface ISubscriptionBenefitGroup {
  benefitDescription: Nullable<string>;
  benefitsData: ISubscriptionAvailableBenefit[];
  benefitGroupAttachments: ISubscriptionAttachment[];
  blockBenefitGroups: string[];
  groupType: number;
  id: string;
  isDisabled?: boolean;
  manualActivation: boolean;
  manualActivationIsDefault: boolean;
  manualActivationType: Nullable<SubscriptionManualActivationType>;
  manualActivationTypeIsDefault: boolean;
  maxChoices: Nullable<number>;
  maxPeople: Nullable<number>;
  maxEmployeeAmount: Nullable<string>;
  minChoices: Nullable<number>;
  minPeople: Nullable<number>;
  name: string;
  pendingDay: Nullable<number>;
  position: number;
  selectionWindowOpenToDate: string;
  selectionWindowOpenToDay: number;
  selectionWindowType: SubscriptionWindowType;
  type: SubscriptionGroupType;
}

export interface IVariant {
  "@id": string;
  "@type": string;
  "id": string;
  "variant": string;
  "active": boolean;
}

export interface ISubscriptionEmployeeGroup {
  benefitEmployeeGroupPrices: ISubscriptionEmployeeGroupPrice[];
  currentPriceType: ISubscriptionPriceType;
  variants: IVariant[],
  currentPriceVariant?: ISubscriptionPriceVariant;
  description: Nullable<string>;
  descriptionIsDefault: boolean;
  id: string;
  name: Nullable<string>;
  nameIsDefault: boolean;
}

export interface ISubscriptionEmployeeGroupPrice {
  affordable: boolean;
  createdAt: string;
  id: string;
  currentPriceVariant: ISubscriptionPriceVariant;
  newPriceActiveFrom?: Nullable<string>;
  newPriceVariant?: Nullable<object>;
  pointsBankId?: string;
  pointsBankName?: string;
  priceVariant?: object;
  variants?: IVariant[],
}

interface ISubscriptionPriceType {
  commissionPercentage: Nullable<number>;
  employerPriceAlternativeText: Nullable<string>;
  employerPriceType: SubscriptionEmployerPriceType;
  priceVariants: Nullable<string[]>;
  id?: string;
  quantity?: number;
}

export interface IBenefitDraftData {
  "@id": string;
  "@type": string;
  benefit: string;
  benefitEmployeeGroupPrice: IbenefitEmployeeGroupPrice;
  collectedDataScope: string;
  id: string;
  pricing: {
    "@id": string;
    "@type": SubscriptionResourceType.BENEFIT_EMPLOYEE_GROUP;
    currentPriceType: {
      "@id": string;
      "@type": SubscriptionResourceType.PRICE_TYPE_VALUE_OBJECT;
      employerPriceAlternativeText: Nullable<string>;
      employerPriceType: SubscriptionEmployerPriceType;
    };
    currentPriceVariant: {
      "@id": string;
      "@type": SubscriptionResourceType.PRICE_VARIANT_VALUE_OBJECT;
      employeePrice: string;
      employerPrice: string;
    };
  };
  value: string;
  quantity: number;
}

interface IbenefitEmployeeGroupPrice {
  "@id": string;
  "@type": string;
  currentPriceVariant: ISubscriptionPriceVariant;
  pointsBankId: Nullable<string>;
  pointsBankName: Nullable<string>;
}

export interface ISubscriptionPriceVariant {
  "@type": string;
  employeePayrollNumber: Nullable<string>;
  employeePayUPrice: Nullable<string>;
  employeePrice: Nullable<string>;
  employerPayrollNumber: Nullable<string>;
  employerPrice: Nullable<string>;
  method: SubscriptionMethod;
  pointsBankPrice: Nullable<string>;
}

interface IPointsBank {
  name: string;
  pointsBankId: string;
  pointsBankPrice: string;
}

export interface ISubscriptionTotals {
  "@id"?: string;
  "@type"?: SubscriptionResourceType.TOTALS;
  employeePriceSummary?: string;
  employeePayUPriceSummary?: string;
  employerPriceSummary?: string;
  items?: ISubscriptionTotalsItem[];
  pointsBanks?: ISubscriptionPointsBank[];
  totalPriceSummary?: string;
}

export interface ISubscriptionTotalsItem {
  "@id": string;
  "@type": SubscriptionResourceType.TOTALS_ITEM;
  benefitId: string;
  employeePrice: string;
  employerPrice: string;
  employeePayUPrice?: number;
  name?: string;
  method?: SubscriptionMethod;
  points?: number;
  pointsBankPrice?: string;
  quantity: number;
}

export interface ISubscriptionAsideTotalsItem {
  benefitId: string;
  employeePayUPrice: number;
  employeePrice: number;
  employerPrice: number;
  name: string;
  points: number;
  pointsBank: string;
  pointsBankId: string;
}

export interface ISubscriptionPointsBank {
  name: string;
  pointsBankId: string;
  pointsBankPrice: string;
}

export interface ISubscriptionForm {
  "@id": string;
  "@type": SubscriptionResourceType.FORM;
  active?: boolean;
  completed: boolean;
  fields: ISubscriptionFormField;
  fieldsStructured: ISubscriptionFormFieldsStructured[];
  graceDate?: string;
  id: string;
  itemActive: boolean;
  receiver: SubscriptionFormReceiver;
  receiverEmail: Nullable<string>;
  receiverId: string;
  scope: SubscriptionCollectedDataScope;
  values: ISubscriptionFormField;
  awaitsUpdate: boolean;
  lastSportCardRequestAt?: string;
  submitted?: boolean;
  duplicateOrderStatus?: string;
  ignoreValidate?: boolean;
  type: FormType;
}

export interface ISubscriptionFormField {
  lastname: string;
  firstname: string;
  pesel?: string;
  email?: string;
}

export interface ISubscriptionFormFieldsStructured {
  value: string;
  label: string;
  type: "lastname" | "firstname" | "pesel" | "email";
}

export interface ISubscriptionActiveFormFieldsStructuredStandard {
  id: string;
  label: string;
  value: string;
  type: Exclude<SubscriptionActiveFormFieldType, "CHECKBOX" | "REQUIRE_PESEL">;
}

interface ISubscriptionActiveFormFieldsStructuredCheckbox {
  id: string;
  label: string;
  value: boolean;
  type: SubscriptionActiveFormFieldType.CHECKBOX;
}

export type RequirePeselOptions = [
  {
    id: "pesel";
    value: string;
  },
  {
    id: "birthDate";
    value: number;
  },
  {
    id: "babyOrForeigner";
    value: boolean;
  }
];

export interface ISubscriptionActiveFormFieldsStructuredRequirePesel {
  id: string;
  label: null;
  options: RequirePeselOptions;
  type: SubscriptionActiveFormFieldType.REQUIRE_PESEL;
}
export type ISubscriptionActiveFormFieldsStructured =
  | ISubscriptionActiveFormFieldsStructuredStandard
  | ISubscriptionActiveFormFieldsStructuredRequirePesel
  | ISubscriptionActiveFormFieldsStructuredCheckbox;

export type SubscriptionFormField =
  | ISubscriptionActiveFormFieldsStructured
  | ISubscriptionFormFieldsStructured;

interface ISubscriptionPdfBarcode {
  "@context": string;
  "@id": string;
  "@type": string;
  id: string;
  barcode: string;
  accepted: boolean;
  attachmentName: string;
  generateUrl: string;
}

interface ISubscriptionPdfForm {
  "@id": string;
  "@type": SubscriptionResourceType.PDF_FORM;
  accepted: boolean;
  description: string;
  id: string;
  receiver: SubscriptionFormReceiver;
  withBarcode: boolean;
}

// TBD - old data structure
export interface ISubscriptionActiveForm {
  "@id": string;
  "@type": SubscriptionResourceType.ACTIVE_FORM;
  active?: boolean;
  completed: boolean;
  data: SubscriptionActiveFormData[];
  description: string;
  fields: Nullable<IActiveFormValues>;
  fieldsStructured: ISubscriptionActiveFormFieldsStructured[];
  formConfig: string;
  graceDate?: string;
  id: string;
  itemActive: boolean;
  name: string;
  receiver: SubscriptionFormReceiver;
  receiverEmail?: Nullable<string>;
  receiverId: string;
  submitted?: boolean;
  awaitsUpdate: boolean;
  lastSportCardRequestAt?: string;
  duplicateOrderStatus?: string;
}

export interface ISubscriptionReceiverActiveForm {
  "@id": string;
  "@type": SubscriptionResourceType.ACTIVE_FORM;
  configuration: SubscriptionActiveFormData[];
  description: string;
  id: string;
  name: string;
}

export type ContentTypeActiveFormData = {
  checkboxRequired: boolean;
  content: string;
  id: string;
  name: string;
  type: SubscriptionActiveFormDataType.CONTENT;
  useCheckbox: boolean;
};

export type FormTypeActiveFormData = {
  id: string;
  fields: SubscriptionActiveFormField[];
  type: SubscriptionActiveFormDataType.FORM;
};

export type HeaderTypeActiveFormData = {
  id: string;
  type: SubscriptionActiveFormDataType.HEADER;
  value: string;
};

export type SubscriptionActiveFormData =
  | ContentTypeActiveFormData
  | FormTypeActiveFormData
  | HeaderTypeActiveFormData;

interface StandardField {
  id: string;
  label: string;
  require?: boolean;
  requirementDependency?: string;
  tooltip?: string;
  type: Exclude<
    SubscriptionActiveFormFieldType,
    | SubscriptionActiveFormFieldType.REQUIRE_PESEL
    | SubscriptionActiveFormFieldType.SELECT
  >;
}

export interface RequirePeselField
  extends Pick<
    StandardField,
    "id" | "tooltip" | "require" | "requirementDependency"
  > {
  type: SubscriptionActiveFormFieldType.REQUIRE_PESEL;
}

interface SelectField
  extends Pick<
    StandardField,
    "id" | "label" | "require" | "requirementDependency" | "tooltip"
  > {
  type: SubscriptionActiveFormFieldType.SELECT;
  title: string;
  options: {
    label: string;
    order: number;
    value: string;
  }[];
}

export type SubscriptionActiveFormField =
  | StandardField
  | RequirePeselField
  | SelectField;

export interface ISubscriptionAttachment {
  "@id": string;
  "@type": SubscriptionResourceType.ATTACHMENT;
  attachmentFiles?: ISubscriptionAttachmentFile[];
  benefitGroupAttachmentFiles?: ISubscriptionAttachmentFile[];
  benefitSubgroupAttachmentFiles?: ISubscriptionAttachmentFile[];
  name: string;
  type: SubscriptionAttachmentType;
}

interface ISubscriptionAttachmentFile {
  "@id": string;
  "@type": SubscriptionResourceType.ATTACHMENT_FILE;
  id: string;
  language: string;
}

export enum FormType {
  ACTIVE_FORM = "active_form",
  FORM = "form",
}

export enum FormView {
  CONFIGURATOR = "configurator",
  MY_SUBSCRIPTIONS = "my_subscriptions",
}

export enum FormPreviewMode {
  BUTTONS,
  EDITABLE_PREVIEW,
}
export interface ISubscriptionReceiver {
  "@id": string;
  "@type": SubscriptionResourceType.SUBSCRIPTION_ITEM_RECEIVER;
  code: string;
  data: object[];
  duplicateOrderStatus?: string;
  expectedEndsAt?: string;
  form: ISubscriptionReceiverForm;
  formType: FormType;
  graceDate?: string;
  id: string;
  isFormOpenForThisReceiver?: boolean;
  lastSportCardRequestAt: string;
  pending: boolean;
  price: {
    employeePayUPrice: string;
    employeePrice: string;
    employerPrice: string;
    pointsBankPrice: string;
  };
  receiverPdfBarcodes: ISubscriptionPdfBarcode[];
  status: ReceiverStatus;
  subscriptionItemReceiver: string;
  type: SubscriptionFormReceiver;
  startsAt: string;
  endsAt?: string;
  representative?: boolean;
}

export type IHandleToggleEditModal = (
  updatedItem: ISubscriptionItemCurrentUserSubscription,
  initialValues: IFormsValue,
  formsEditedByUsers: string[],
  duplicateTrigger?: boolean
) => void;

export interface ISubscriptionReceiverForm {
  "@type": SubscriptionResourceType.RECEIVER_FORM;
  activeForm: Nullable<ISubscriptionReceiverActiveForm>;
  awaitsUpdate: boolean;
  completed: boolean;
  emailSentAt?: string;
  fields: ISubscriptionReceiverFormField[];
  formConfig?: string;
  id: string;
  ignoreValidate: boolean;
  itemActive: boolean;
  nextSendAfter?: string;
  lastSportCardRequestAt: Nullable<string>;
  receiverEmail: Nullable<string>;
  scope: SubscriptionCollectedDataScope;
  sendCounter: number;
  submitted: boolean;
  type: FormType;
}

export interface ISubscriptionReceiverFormField {
  id: string;
  label: string;
  options?: ISubscriptionReceiverFormFieldOption[];
  type: SubscriptionFormFieldType | SubscriptionActiveFormFieldType;
  value: string;
  required: boolean;
}

export interface ISubscriptionReceiverFormFieldOption {
  [fieldId: string]: string;
}

export interface ISubscriptionReceiverFormValues {
  [fieldId: string]: string | RequirePeselValue;
}

export type HandleToggleReceiversModalFn = (
  updatedReceiver: Nullable<{
    id: string;
    fields?: ISubscriptionReceiverFormValues;
    receiverEmail?: string;
    nextSendAfter?: string;
  }>,
  duplicateTrigger?: boolean
) => void;

export interface ISubscriptionAgreement {
  "@id": string;
  "@type": string;
  accepted: boolean;
  acceptedDate?: Nullable<string>;
  agreementContent: string;
  declarationType?: SubscriptionDeclarationType;
  agreementType: SubscriptionAgreementType;
  showAgreement: boolean;
}

interface ISubscriptionBenefitAgreement {
  agreements: ISubscriptionAgreement[];
  id: string;
  name: string;
}

export interface ISubscriptionAgreementFormValue {
  initialValue: boolean;
  label: string;
  name: string;
  rules: string[];
  type: SubscriptionAgreementType;
}

export interface ISubscriptionActiveFormResendBlocked {
  nextAvailableDate: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
}

export type SubscriptionActiveFormResend =
  Nullable<ISubscriptionActiveFormResendBlocked>;

export type RequirePeselValue = {
  babyOrForeigner: boolean;
  birthDate: Nullable<number>;
  pesel: string;
};

export type ActiveFormValue = boolean | number | string | RequirePeselValue;

export interface IActiveFormValues {
  [key: string]: ActiveFormValue;
}

// TBD
export interface IFormsValue {
  [benefitId: string]: {
    activeForms?: {
      [formId: string]: {
        receiverEmail: string;
      };
    };
    forms?: {
      [formId: string]: {
        fields: Record<keyof ISubscriptionFormField, string>;
      };
    };
  };
}

export interface ISubscriptionFormsValues {
  [subscriptionId: string]: {
    [formId: string]: {
      fields: ISubscriptionFormsFields;
    };
  };
}

export interface ISubscriptionFormData {
  edited: boolean;
  error: boolean;
  fields: ISubscriptionFormsFields;
  filledInByUser: boolean;
}

export interface ISubscriptionFormsFields {
  [fieldId: string]: string | RequirePeselValue;
}

export enum SubscriptionFormActions {
  DEFAULT = "DEFAULT",
  EDIT = "EDIT",
  PREVIEW = "PREVIEW",
  PREVIEW_OTHERS = "PREVIEW_OTHERS",
  PREVIEW_AND_EDIT = "PREVIEW_AND_EDIT",
  RESEND = "RESEND",
  NONE = "NONE",
}
export interface ISubscriptionFormMode {
  actions: SubscriptionFormActions;
  awaitsUpdate: boolean;
  employeeEditDisabled: boolean;
  externalForm: boolean;
  nameFieldsDisabled: boolean;
  preview: boolean;
  resend: boolean;
}
export interface ITotalsCalculatedItem {
  benefitId: string;
  employeePayUPrice: number;
  employeePrice: number;
  employerPrice: number;
  name: string;
  points: number;
  pointsBank: string;
  pointsBankId: string;
  pointsBankPrice?: number;
}

export interface ITotalsCalculated {
  employeePayUPriceSummary: number;
  employeePointsSummary: number;
  employeePriceSummary: number;
  employerPriceSummary: number;
  items: ITotalsCalculatedItem[];
  totalPriceSummary: number;
}

interface ICheckoutSubscriptionTemplateProps {
  totalsIsLoading: boolean;
  totalsError: boolean | string;
  isNextStepDisabled?: boolean;
  nextStep: () => void;
  prevStep: () => void;
}

export interface ISubscriptionCheckoutConfigurationStepTemplateProps
  extends ICheckoutSubscriptionTemplateProps {
  step: SubscriptionCheckoutSteps.CONFIGURATION;
  group: SubscriptionGroups;
  totals: ITotalsCalculated;
}

export interface ISubscriptionCheckoutStepsDefaultTemplateProps
  extends ICheckoutSubscriptionTemplateProps {
  step:
    | SubscriptionCheckoutSteps.INFORMATIONS
    | SubscriptionCheckoutSteps.AGREEMENTS
    | SubscriptionCheckoutSteps.SUMMARY;
  totals: ISubscriptionTotals;
}

export type SubscriptionCheckoutStepsTemplateProps =
  | ISubscriptionCheckoutConfigurationStepTemplateProps
  | ISubscriptionCheckoutStepsDefaultTemplateProps;

export const enum SubscriptionStatus {
  CANCELED = "canceled",
  PENDING = "pending",
  PENDING_ACCEPTED = "pending_accepted",
  ACTIVE = "active",
  RESIGNED = "resigned",
  SUSPENDED = "suspended",
  DENIED = "denied",
  READY = "ready",
  WAITING_FOR_ACTIVE_FORMS = "waitingForActiveForms",
  WAITING_FOR_PDF_FORMS = "waitingForPdfForms",
  NEW_PERSON = "new_person",
  PROCESSING = "processing",
  BENEFIT_PRICING_CHANGED = "benefit_pricing_changed",
}

export const enum SubscriptionStatusCases {
  STATUS_CASE = "statusCase",
  QUANTITY_CASE = "quantityCase",
}

export const enum ReceiverStatus {
  ACTIVE = "active",
  CANCELED = "canceled",
  PENDING = "pending",
  PENDING_ACCEPTED = "pending_accepted",
  PROCESSING = "processing",
  RESIGNED = "resigned",
}

type SubscriptionWindowType = "individual" | "oneTime" | "period";

export enum SubscriptionFormReceiver {
  EMPLOYEE = "employee",
  OTHERS = "others",
}

export type IBenefitTypes = (
  | SubscriptionRelationType
  | SubscriptionRelationFreeType
)[][];

export enum SubscriptionAttachmentType {
  FORM = "form",
  REGULATIONS = "regulations",
  PDF_BARCODE = "pdf_barcode",
  OTHER = "other",
}

export type SubscriptionRelationType =
  | "auto"
  | "free"
  | "optional"
  | "mandatory"
  | "grace";
export type SubscriptionRelationFreeType =
  | "dependent"
  | "independent"
  | "replacing";

export enum SubscriptionEmployerPriceType {
  FIXED = "fixed",
  HIDDEN = "hiddenEmployerPrice",
  VARIANT = "variantPrice",
}

export enum SubscriptionMethod {
  EMPLOYEE = "employee",
  EMPLOYER = "employer",
  CO_FINANCED = "coFinanced",
  POINTS = "points",
}

export enum SubscriptionPurchaseMethod {
  GROUP = "group",
  INDIVIDUAL = "individual",
}

export enum SubscriptionCollectedDataScope {
  ACTIVE_FORM = "activeForm",
  EMAIL = "email",
  EMAIL_WITH_PESEL = "emailWithPesel",
  NAMES = "names",
  PESEL = "pesel",
  NULL = "null",
}

type SubscriptionManualActivationType = "all" | "salary";
export type SubscriptionGroupType = "single" | "multichoice";
export type SubscriptionAgreementType =
  | "fromInsuranceAgreement"
  | "fromSalaryAgreement"
  | "personalDataAgreement"
  | "regulationAgreement";

export enum SubscriptionDeclaration {
  NONE = "none",
  ONLINE = "online",
  OFFLINE = "offline",
}

export type SubscriptionDeclarationType =
  | SubscriptionDeclaration.NONE
  | SubscriptionDeclaration.ONLINE
  | SubscriptionDeclaration.OFFLINE;

export enum SubscriptionActiveFormDataType {
  CONTENT = "content",
  FORM = "form",
  HEADER = "header",
}

export enum SubscriptionActiveFormFieldType {
  BIRTHDATE = "birthDate",
  CHECKBOX = "checkbox",
  DATE = "date",
  EMAIL = "email",
  FIRSTNAME = "firstname",
  LASTNAME = "lastname",
  PESEL = "pesel",
  PHONE = "phone",
  POSTCODE = "postcode",
  REQUIRE_PESEL = "requirePesel",
  SELECT = "select",
  TEXT = "text",
  NUMBER = "number",
  CONFIRM_EMAIL = "confirmEmail",
}

export enum SubscriptionFormFieldType {
  FIRSTNAME = "firstname",
  LASTNAME = "lastname",
  PESEL = "pesel",
  EMAIL = "email",
  RECEIVER_EMAIL = "receiverEmail",
  PHONE = "phone",
}

export type MethodTypes = "employee" | "employer" | "employeePayU" | "points";

export interface ICurrentPriceData {
  benefitEmployeeGroupPrice: string;
  matchPriceVariant: {
    benefitPricesData: [];
  };
  method: MethodTypes;
  pointsBank: string;
  pointsBankId: string;
  priceUnit: string;
  pricing: string;
  quantity?: number;
}
export interface PriceData {
  price: Nullable<string> | { benefitPricesData: [] };
  method: string;
  pointsBankId?: string | null;
  pointsBank?: string | null;
}
export interface IDuplicateCardData {
  duplicateInfo: string | null;
  organizationUnits: [{ id: string; name: string }] | [];
  ownList: string[] | [];
  placeDuplicateDeliveredType: string;
  showDuplicateInfo: boolean;
  showPlaceDuplicateDelivered: boolean;
  workPlaces: string[] | [];
}

export enum DuplicateOrderStatus {
  NEVER_ORDERED = "never_ordered",
  JUST_ORDERED = "just_ordered",
  ORDERED_IN_THE_PAST = "ordered_in_the_past",
  UNAVAILABLE = "unavailable",
}

export interface IDuplicateCardFormState {
  reason?: { value?: string; required?: boolean; id?: string };
  place?: { value?: string; required?: boolean; id?: string };
  userComment?: { value?: string; required?: boolean; id?: string };
  newFirstName?: string | { value?: string; required?: boolean; id?: string };
  newLastName?: string | { value?: string; required?: boolean; id?: string };
  street?: string | { value?: string; required?: boolean; id?: string };
  city?: string | { value?: string; required?: boolean; id?: string };
  "post-code"?: string | { value?: string; required?: boolean; id?: string };
  houseNumber?: string | { value?: string; required?: boolean; id?: string };
  flatNumber?: string | { value?: string; required?: boolean; id?: string };
}

export interface IEditModalProps {
  updatedItem: ISubscriptionItemCurrentUserSubscription;
  initialValues: IFormsValue;
  formsEditedByUsers: string[];
  duplicateTrigger?: boolean;
}

export type List = {
  id?: string;
  value?: string;
  name?: string;
};

export type DuplicateCardPayload = {
  id?: string;
  value?: string;
  required?: boolean;
  newFirstName?: string;
  newLastName?: string;
  place?: { value?: string; required?: boolean };
};

export interface IDuplicateFormItem {
  index: number;
  inputType: string;
  label: string;
  list: List[];
  placeholder: string;
  position: number;
  required: boolean;
  showWhen: string[];
  type: string;
}

export interface IDuplicateFormData extends ISubscriptionActiveForm {
  benefitFormsWithDuplicateStatus?: string;
}

export const enum DuplicateCardFormActions {
  reason = "reason",
  place = "place",
  USER_COMMENT = "USER_COMMENT",
  CLEAR_NAME_LASTNAME = "CLEAR_NAME_LASTNAME",
  NAME_LASTNAME_REQUIRED = "NAME_LASTNAME_REQUIRED",
  PLACE_REQUIRED = "PLACE_REQUIRED",
  CREATE_ADDRESS_DATA = "CREATE_ADDRESS_DATA",
}

export const enum ModeReactivationSubscription {
  ALL = "all",
  PURCHASED_IN_SYSTEM = "purchasedInSystem",
}

export const enum ChoiceButtonType {
  MY_SUBSCRIPTIONS = "MY_SUBSCRIPTIONS",
  CHANGE_SUBSCRIPTIONS = "CHANGE_SUBSCRIPTIONS",
}

export const enum ConfiguratorCheckboxLabel {
  DEFAULT = "Wybierz",
  ORDER_NEW_CARD = "Zamów nową kartę",
  ORDER_OR_ACTIVATE_CARD = "Zamów / aktywuj",
}

export const enum SubscriptionsDatesInfoVariants {
  DEFAULT = "default",
  LARGE = "large",
  PRODUCT = "product",
}

export interface ISubscriptionItemMs {
  id: string;
  sku: string;
  subscription_group: string;
}

export const enum IRI {
  BENEFIT = "/api/subscription-management/v1/rest/benefits",
  GROUP = "/api/subscription-management/v1/rest/benefit-groups",
  RECEIVER = "/api/subscription-management/v1/rest/subscription-item-receivers",
}

export interface IBenefitRelationToOtherBenefits {
  newValues: ISubscriptionConfiguratorValuesFormik;
  groupId: string;
  benefit: ISubscriptionAvailableBenefit;
  replacingBenefitsForAuto: ISubscriptionAvailableBenefit[];
  replacingBenefitForOtherReplacing: ISubscriptionAvailableBenefit[];
  allBenefitsDependingOnCurrentBenefit: ISubscriptionAvailableBenefit[];
  allBenefits: ISubscriptionAvailableBenefit[];
  getAutoBenefitGroupId: any;
  getBenefitData: any;
  thresholdDay?: Nullable<number>;
  currentBenefitFromDraft: any;
}

export interface IIsBlockadeByGraceDate {
  startAt: string;
  thresholdDay: number | string;
  resignationPeriod: Nullable<number>;
  relationRestrictionPeriod?: Nullable<number>;
  relationExtensionPeriod?: Nullable<number>;
  status?: string;
}

export interface ISubscriptionDraftItemReceiver {
  "@type": string;
  id: string;
  form: ISubscriptionReceiverForm;
  formType: string;
  graceDate: string;
  startsAt: string;
  subscriptionItemReceiver: string;
  type: string;
}
