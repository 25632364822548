import { FC } from "react";

import Product from "web/Layout/ProductCard";

import { PropsWithClasses } from "web/types/Common";
import { IProduct } from "web/types/Product";

import classify from "web/classify";

import defaultClasses from "./products.scss";

interface IPageGeneratorTwoProductsProductsProps {
  items: IProduct[];
  listingPosition: number;
  listingAmount: number;
  listingCategory: string;
}

const PageGeneratorTwoProductsProducts: FC<
  PropsWithClasses<IPageGeneratorTwoProductsProductsProps>
> = ({
  classes = {},
  items,
  listingPosition,
  listingAmount,
  listingCategory = "",
}) => {
  return (
    <ul className={classes.list} data-t1="page_generator_two_products_products">
      {items?.map((product, index) => {
        const positionIndex = index + 1;
        return (
          <li
            className={classes.item}
            key={product && product.id}
            data-t1="page_generator_two_products_product"
          >
            <Product
              product={product}
              positionNumber={positionIndex}
              listingAmount={listingAmount}
              listingPosition={listingPosition}
              listingCategory={listingCategory}
              variant="promo"
            />
          </li>
        );
      })}
    </ul>
  );
};

export default classify<
  PropsWithClasses<IPageGeneratorTwoProductsProductsProps>
>(defaultClasses)(PageGeneratorTwoProductsProducts);
