/* eslint-disable @typescript-eslint/ban-ts-comment */
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { ICategoryCount } from "web/types/CategoryCount";
import { IParsedParameter } from "web/types/PageGenerator";

interface IGetImagesToRenderArgs {
  availableCategories: ICategoryCount[];
  params: IParsedParameter;
}

const getImagesToRender = ({
  params,
  availableCategories,
}: IGetImagesToRenderArgs) => {
  const images: (IParsedParameter | false)[] = Object.keys(params).map(
    (key) => {
      // @ts-ignore
      return typeof params[key] !== "string" && params[key];
    }
  );
  return images.filter((image) => {
    if (!image) {
      return false;
    }

    if (!image.image_category_ids) {
      return true;
    }

    if (image.image_category_ids.includes(",")) {
      const slideCategoryIdsArray = image.image_category_ids.split(",");
      const areAllCategoriesAvailable = slideCategoryIdsArray.every(
        (slideIdCat: string) => {
          return (
            availableCategories &&
            availableCategories.find((availableCat) => {
              return +availableCat.id === +slideIdCat;
            })
          );
        }
      );
      return areAllCategoriesAvailable ? image : null;
    }
    return isArrayHasItems(availableCategories)
      ? availableCategories.find((category) => {
          return (
            image.image_category_ids === category.id &&
            +category.product_count > 0
          );
        })
      : null;
  }) as IParsedParameter[];
};

export default getImagesToRender;
