import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { SOURCE_FOUND_LABEL } from "web/constants/benefits";

import { Classes } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./fundingSource.scss";

interface IFundingSourceProps {
  classes?: Classes;
  fundingSourceData: { label: string; dataT1: string }[];
}

const FundingSource = ({
  classes = {},
  fundingSourceData,
}: IFundingSourceProps) => {
  return (
    <>
      {isArrayHasItems(fundingSourceData) && fundingSourceData?.length > 1 && (
        <span className={classes.columnOne__label} data-t1="fundingSourceTitle">
          {__("Współfinansowany")}
        </span>
      )}
      {isArrayHasItems(fundingSourceData) &&
        fundingSourceData?.map((ele) => (
          <div
            key={ele.label}
            className={
              fundingSourceData.length > 1 ? classes.columnOne__value : ""
            }
            data-t1={ele.dataT1}
          >
            {ele.label}
          </div>
        ))}
      {isArrayHasItems(fundingSourceData) &&
        fundingSourceData[0].label === SOURCE_FOUND_LABEL.employee && (
          <span className={classes.employeeSubText}>
            {__(SOURCE_FOUND_LABEL.employeeSubText)}
          </span>
        )}
    </>
  );
};

export default classify<IFundingSourceProps>(defaultClasses)(FundingSource);
